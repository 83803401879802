<template>
  <v-row>
    <div class="col-12 px-0">
      <v-form ref="condominioform" v-model="valid" lazy-validation>
        <div v-if="this.condos_response.selected_role == 'manager'">
          <div>
            <v-col cols="12" class="pt-0 text text-5 normal-label">
              Nome do condomínio
            </v-col>
            <v-col cols="12" class="pt-0 pb-0 px-3">
              <v-text-field
                :placeholder="
                  profiles_dmp[0].condominium_account != null
                    ? profiles_dmp[0].condominium_account.name
                    : '-'
                "
                height="44px"
                background-color="#D8D8D8"
                class="filled-input"
                dense
                outlined
                disabled
              />
              <!--Change to name after API change-->
            </v-col>
          </div>
          <div>
            <v-col cols="12" class="pt-0 text text-5 normal-label"> NIF </v-col>
            <v-col cols="12" class="pt-0 pb-0 px-3">
              <v-text-field
                :placeholder="
                  profiles_dmp[0].condominium_account != null
                    ? profiles_dmp[0].condominium_account
                        .tax_identification_number
                    : '-'
                "
                height="44px"
                background-color="#D8D8D8"
                class="filled-input"
                dense
                outlined
                disabled
              />
            </v-col>
          </div>
          <div>
            <v-col cols="12" class="pt-3 text text-5 normal-label">
              Fração
            </v-col>
            <v-col cols="12" class="pt-0 pb-0 px-3">
              <v-text-field
                :placeholder="
                  default_address_condo != null
                    ? default_address_condo.fraction
                    : '-'
                "
                height="44px"
                background-color="#D8D8D8"
                class="filled-input"
                dense
                outlined
                disabled
              />
            </v-col>
          </div>
          <v-row class="px-3 pt-5 py-0">
            <v-col cols="6">
              <v-row>
                <v-col cols="12" class="pt-0 text text-5 normal-label">
                  Código Postal
                </v-col>
                <v-col cols="12" class="pt-0 pb-0 px-3">
                  <v-text-field
                    :placeholder="
                      default_address_condo != null
                        ? default_address_condo.postal_code
                        : '-'
                    "
                    height="44px"
                    background-color="#D8D8D8"
                    class="filled-input"
                    dense
                    outlined
                    disabled
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="12" class="pt-0 text text-5 normal-label">
                  Cidade
                </v-col>
                <v-col cols="12" class="pt-0 pb-0 px-38">
                  <v-text-field
                    :placeholder="
                      default_address_condo != null
                        ? default_address_condo.city
                        : '-'
                    "
                    height="44px"
                    background-color="#D8D8D8"
                    class="filled-input"
                    dense
                    outlined
                    disabled
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-col cols="12" class="pt-3 text text-5 normal-label">
            Morada
          </v-col>
          <v-col cols="12" class="pt-0 pb-5 px-3">
            <v-text-field
              :placeholder="
                default_address_condo != null
                  ? default_address_condo.address
                  : '-'
              "
              height="44px"
              background-color="#D8D8D8"
              class="filled-input"
              dense
              outlined
              disabled
            />
          </v-col>
        </div>

        <v-col cols="12" class="mb-5">
          <label class="switch">
            <input type="checkbox" v-model="usePersonalData" />
            <span class="slider round"></span>
          </label>
          <span class="pl-3"
            >Usar os detalhes do condomínio para efeitos de faturação</span
          >
        </v-col>
        <div v-if="usePersonalData" class="test-animation mb-5">
          <v-col cols="12" class="pt-4 pb-4">
            <form-textbox
              :title="'Nome completo'"
              :placeholder="'Nome completo'"
              :profile_key="{
                index: this.condos_response.selected_profile,
                key: 'name',
                address_type: 'Billing Address',
                account: 'condominium_account',
                value: 'name',
              }"
              :title_alone="false"
              :bold="true"
              :rules="[requiredRule]"
            />
          </v-col>

          <v-col cols="12" class="pt-4 pb-4">
            <form-textbox
              :title="'NIF'"
              :placeholder="'000000000'"
              :profile_key="{
                index: this.condos_response.selected_profile,
                key: 'tax_identification_number',
                address_type: 'Billing Address',
                account: 'condominium_account',
              }"
              :title_alone="false"
              :bold="true"
              :rules="[requiredRule]"
              :typeNumber="true"
            />
          </v-col>

          <v-col cols="12" class="pt-4 pb-5">
            <form-textbox
              :title="'Morada'"
              :placeholder="'Rua'"
              :profile_key="{
                index: this.condos_response.selected_profile,
                key: 'address',
                address_type: 'Billing Address',
                account: 'condominium_account',
              }"
              :title_alone="false"
              :bold="true"
              :rules="[requiredRule]"
            />
          </v-col>
          <v-col cols="12" class="pt-4 pb-5">
            <form-textbox
              :title="'Fração'"
              :placeholder="'1 DTO'"
              :optional_field="'(Opcional)'"
              :profile_key="{
                index: this.condos_response.selected_profile,
                key: 'fraction',
                address_type: 'Billing Address',
                account: 'condominium_account',
              }"
              :title_alone="false"
              :bold="true"
              :noValidate="true"
            />
          </v-col>

          <v-row class="px-3 py-0">
            <v-col cols="6" class="pt-4 pb-2">
              <form-textbox
                :title="'Código Postal'"
                :placeholder="'0000-000'"
                :profile_key="{
                  index: this.condos_response.selected_profile,
                  key: 'postal_code',
                  address_type: 'Billing Address',
                  account: 'condominium_account',
                }"
                :title_alone="false"
                :bold="true"
                :rules="[requiredRule]"
              />
            </v-col>
            <v-col cols="6" class="pt-4 pb-2">
              <form-textbox
                :title="'Cidade'"
                :placeholder="'Cidade'"
                :profile_key="{
                  index: this.condos_response.selected_profile,
                  key: 'city',
                  address_type: 'Billing Address',
                  account: 'condominium_account',
                }"
                :title_alone="false"
                :bold="true"
                :rules="[requiredRule]"
              />
            </v-col>
          </v-row>
        </div>

        <v-col cols="12" class="mb-10">
          <submit-button
            @callEvent="validate"
            :name="'handle-condos-profile'"
            :loading="loading"
            :height="35"
            :title="'Guardar'"
            :textSize="true"
          />
        </v-col>
      </v-form>
    </div>

    <success-modal
      :modal-name="'handle-condos-profile'"
      :header="'Alterações gravadas!'"
      :text="'As tuas Alterações foram gravadas.'"
      :icon="'Union.svg'"
    />
    <success-modal
      :modal-name="'handle-user-profile'"
      :icon="'Union.svg'"
      :header="'Alterações gravadas!'"
      :text="'As tuas Alterações foram gravadas.'"
    />
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
import formTextbox from "@/components/forms/form-textbox.vue";
import SubmitButton from "@/components/dashboard/submit-button.vue";
import DashboardMode from "@/components/dashboard/dashboard-mode.vue";
import SuccessModal from "@/components/general/success-modal.vue";
import Vue from "vue";

export default mixins(General).extend({
  components: { formTextbox, SubmitButton, DashboardMode, SuccessModal },
  name: "ManageAccount",
  data() {
    return {
      isChecked: false,
      usePersonalData: this.$store.state.doesContoHaveAdress,
      loading: false,
      hasError: false,
      valid: false,
      requiredRule: (v: any) => !!v || "Este campo é obrigatório",
    };
  },
  watch: {
    billing_address_condo_length() {
      this.usePersonalData = !this.billing_address_condo_length.address;
    },
  },
  mounted() {
    this.usePersonalData = !this.billing_address_condo_length.address;
  },
  methods: {
    validate() {
      const isValid = (
        this.$refs.condominioform as Vue & { validate: () => boolean }
      ).validate();
      if (isValid) {
        this.handleCondoProfile();
      }
    },
    handleCondoProfile() {

      this.$store
        .dispatch(
          "sendCondoProfileData",
          this.usePersonalData
            ? this.billing_address_condo_length
            : this.default_address_condo
        )
        .then(() => {
          this.$store.commit("toggleModalState", {
            status: true,
            name: "handle-user-profile",
          });
          this.$store.commit("toggleButtonState", {
            status: false,
            name: "handle-condos-profile",
          });
        });
    },
  },
  computed: {
    profiles_dmp: function (): any {
      return this.$store.state.profiles.filter(
        (profile: any) => profile.id == this.$store.state.condos_response.selected_profile
      );
    },
    default_address_condo() {
      return this.$store.state.profiles
        .filter(
          (profile: any) => profile.id == this.$store.state.condos_response.selected_profile
        )[0]
        .condominium_account.addresses.filter(
          (address: any) => address.address_type == "Default Address"
        )[0];
    },
  },
});
</script>

<style lang="scss"></style>
